@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}
